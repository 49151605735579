import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';

const UserProfile = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Check if the viewport width is greater than or equal to 1200px
    const isDesktop = useMediaQuery('(min-width:1200px)');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`http://localhost:5000/api/profile/${userId}`);
                setUser(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user profile:', error.response || error);
                setError(error.response?.data?.error || 'An error occurred while fetching the user profile');
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [userId]);

    const handleMessageClick = async () => {
        try {
            await axios.post(
                'http://localhost:5000/api/messages',
                { recipientId: userId },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            alert(`Message sent to user with ID: ${userId}`);
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message');
        }
    };

    const handleAddFriendClick = async () => {
        try {
            await axios.post(
                'http://localhost:5000/api/friend-request',
                { recipientId: userId },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            alert(`Friend request sent to user with ID: ${userId}`);
        } catch (error) {
            console.error('Error sending friend request:', error);
            alert('Failed to send friend request');
        }
    };

    if (loading) return <Box sx={{ p: 3 }}>Loading user profile...</Box>;
    if (error) return <Box sx={{ p: 3, color: 'red' }}>Error: {error}</Box>;
    if (!user) return <Box sx={{ p: 3 }}>No user data found</Box>;

    const buttonStyle = {
        backgroundColor: '#2575fc',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1a5bbf',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                px: isDesktop ? '25%' : 2,
                py: 0,
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    backgroundColor: 'white',
                    p: 3,
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Box sx={{ mt: 5, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 1, marginLeft: 2 }}>
                            <Typography variant="h4" component="h1" align="center" mb={4}>
                                {user.firstName} {user.lastName}'s Profile
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 2 }}>
                                <Box sx={{ textAlign: 'center', mb: { xs: 2, md: 0 } }}>
                                    <img
                                        src={user.profilePicture}
                                        alt="Profile"
                                        style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '20px', borderRadius: '50%' }}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 1 }}>
                                        <Typography><strong>Name:</strong> {user.firstName} {user.lastName}</Typography>
                                        <Typography><strong>Email:</strong> {user.email}</Typography>
                                        <Typography><strong>Bio:</strong> {user.bio}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ textAlign: 'center', mt: 4 }}>
                                <Button variant="contained" sx={buttonStyle} onClick={handleAddFriendClick}>
                                    Send Friend Request
                                </Button>
                                <Button variant="contained" sx={{ ...buttonStyle, ml: 2 }} onClick={handleMessageClick}>
                                    Send Message
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UserProfile;
