import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Account from "./Account";
import Register from "./Register";
import Logout from "./Logout";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import UserProfile from './UserProfile';
import ChatPage from './ChatPage';
import { Route, Routes } from "react-router-dom";
import './App.css';


import { CssBaseline, Container, ThemeProvider } from '@mui/material';
import theme from './theme';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Hero2 from './components/Hero2';
import TestimonialSection from './components/TestimonialSection';
import ServiceSection from './components/ServiceSection';
import GallerySection from './components/GallerySection';
import FeatureSection from './components/FeatureSection';
import TeamSection from './components/TeamSection';
import ContactForm from './components/ContactForm';
import BlogSection from './components/BlogSection';
import PricingSection from './components/PricingSection';
import FaqSection from './components/FaqSection';
import PortfolioSection from './components/PortfolioSection';
import NewsletterSubscription from './components/NewsletterSubscription';
import Testimonials from './components/Testimonials';

import MessagingModal from './components/MessagingModal';
import ResetPassword from './ResetPassword';
import ForgotPassword from "./ForgotPassword";


import 'bootstrap/dist/css/bootstrap.min.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
    

function App() {
    const [message, setMessage] = useState('');

    useEffect(() => {
      // Fetch data from the backend API
     fetch('/api')
       .then(response => response.json())
       .then(data => setMessage(data.message))
        .catch(error => console.error('Error fetching data:', error));
     }, []);

    const isAuthenticated = !!localStorage.getItem('token');

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    };



    return <>
    <ThemeProvider theme={theme}>
        <Navbar />
        <CssBaseline />

            
                <Routes>
                    <Route path="/" element={<Home />}> </Route>
                    <Route path="/About" element={<About />}> </Route>
                    <Route path="/Services" element={<Services />}> </Route>
                    <Route path="/Contact" element={<Contact />}> </Route>
                    <Route path="/Account" element={<Account />}> </Route>
                    <Route path="/Register" element={<Register />}> </Route>
                    <Route path="/ForgotPassword" element={<ForgotPassword />}> </Route>
                    <Route path="/Profile" element={<Profile />}> </Route>
                    <Route path="/Logout" element={<Logout />}> </Route>
                    <Route path="/EditProfile" element={<EditProfile />}> </Route>
                    <Route path="/Hero2" element={<Hero2 />}> </Route>
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/ForgotPassword" element={<ForgotPassword />} />
                    <Route path="/profile/:userId" element={<UserProfile />} />
                    <Route path="/chat/:userId" component={<ChatPage />} />
                </Routes>

                {isAuthenticated && (
                    <MessagingModal />
                )}

        <Footer />
    </ThemeProvider>
        </>
}

export default App