// src/pages/ChatPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const socket = io('http://localhost:5000');

const ChatPage = () => {
    const { userId } = useParams(); // ID of the other user to chat with
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [loading, setLoading] = useState(true);
    const messageEndRef = useRef(null);

    const user = JSON.parse(localStorage.getItem('user')); // Ensure the user info is in localStorage

    useEffect(() => {
        const fetchMessages = async () => {
            const token = localStorage.getItem('token');
            if (!token || !user) return;

            try {
                const response = await axios.get(`http://localhost:5000/api/messages/${user._id}/${userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessages(response.data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();

        socket.emit('join', user._id);

        socket.on('message', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        socket.on('messageStatus', (message) => {
            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg._id === message._id ? { ...msg, status: message.status } : msg
                )
            );
        });

        return () => {
            socket.off('message');
            socket.off('messageStatus');
        };
    }, [userId, user._id]);

    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const sendMessage = () => {
        const token = localStorage.getItem('token');
        if (messageInput.trim() && user) {
            const message = {
                sender: user._id,
                receiver: userId,
                text: messageInput
            };

            socket.emit('sendMessage', message);
            setMessages((prevMessages) => [...prevMessages, { ...message, status: 'sending' }]);
            setMessageInput('');
        }
    };

    const handleInputChange = (e) => {
        setMessageInput(e.target.value);
    };

    return (
        <Box sx={{ p: 3, height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: 1, overflowY: 'auto', mb: 2, p: 1, border: '1px solid #ddd', borderRadius: 1 }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {messages.map((msg) => (
                            <Box key={msg._id} sx={{ mb: 1, p: 1, backgroundColor: msg.sender === user._id ? '#e3f2fd' : '#f5f5f5', borderRadius: 1 }}>
                                <Typography variant="body2">{msg.text}</Typography>
                                <Typography variant="caption" color="textSecondary">{new Date(msg.timestamp).toLocaleTimeString()}</Typography>
                            </Box>
                        ))}
                        <div ref={messageEndRef} />
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', borderTop: '1px solid #ddd', p: 1 }}>
                <TextField
                    fullWidth
                    value={messageInput}
                    onChange={handleInputChange}
                    placeholder="Type a message"
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                />
                <Button variant="contained" onClick={sendMessage}>Send</Button>
            </Box>
        </Box>
    );
};

export default ChatPage;
