import React from 'react';
import { Grid, Box, Typography, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const plans = [
  {
    name: 'Vanilla',
    price: '£199',
    features: ['HTML/CSS', 'JavaScript', 'PHP', 'MySQL'],
  },
  {
    name: 'React.js',
    price: '£299',
    features: ['Front-End', 'Back-End', 'APIs', 'Mobile'],
  },
  {
    name: 'Mern Stack',
    price: '£499',
    features: ['React.js', 'Node.js', 'Express.js', 'MongoDB'],
  },
];

const PricingSection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Pricing Plans
      </Typography>
      <Grid container spacing={4}>
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Item>
              <Typography variant="h6" sx={{ pl: 2 }}>{plan.name}</Typography>
              <Typography variant="h4" sx={{ pl: 2 }}>{plan.price}</Typography>
              <br/>
              <ul>
                {plan.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
              <br/>
              <Button variant="contained" color="primary" sx={{ ml: 4 }}>
                Choose Plan
              </Button>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingSection;
