import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Profile() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                alert('Not authenticated');
                return;
            }

            try {
                const response = await axios.get('http://localhost:5000/api/profile', {
                    headers: {
                        'Authorization': token
                    }
                });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const token = localStorage.getItem('token');

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('http://localhost:5000/api/profile', user, {
            headers: {
                'Authorization': token
            }
        })
            .then(() => {
                navigate('/profile');
                setMessage('Profile updated successfully');
            })
            .catch(() => {
                setError('Error updating profile');
            });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <div>No user data</div>;
    }

    return (
    <div className="PaddedContainer">
        <div className="container mt-5">
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="card" style={{ backgroundColor: '#e4e4e4' }}>
                    <div className="card-body">
                        <h1 className="card-title mb-4 text-center">Edit Profile</h1>
                        <form onSubmit={handleSubmit} className="needs-validation" noValidate>

                            <div className="mb-3">
                                <label htmlFor="linkedin" className="form-label">Linkedin:</label>
                                <input 
                                    type="text" 
                                    id="linkedin" 
                                    name="linkedin" 
                                    className="form-control" 
                                    value={'' || ''} 
                                    onChange={handleChange} 
                                    required 
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="facebook" className="form-label">Facebook:</label>
                                <input 
                                    type="text" 
                                    id="facebook" 
                                    name="facebook" 
                                    className="form-control" 
                                    value={'' || ''} 
                                    onChange={handleChange} 
                                    required 
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="twitter" className="form-label">Twitter:</label>
                                <input 
                                    type="text" 
                                    id="twitter" 
                                    name="twitter" 
                                    className="form-control" 
                                    value={'' || ''} 
                                    onChange={handleChange} 
                                    required 
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="bio" className="form-label">Bio:</label>
                                <textarea 
                                    id="bio" 
                                    name="bio" 
                                    className="form-control" 
                                    rows="4" 
                                    value={user.bio || ''} 
                                    onChange={handleChange} 
                                ></textarea>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="profilePicture" className="form-label">Profile Picture URL:</label>
                                <input 
                                    type="text" 
                                    id="profilePicture" 
                                    name="profilePicture" 
                                    className="form-control" 
                                    value={user.profilePicture || ''} 
                                    onChange={handleChange} 
                                />
                            </div>

                            <div className="text-center">
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                        {message && <p className="mt-3 text-center text-success">{message}</p>}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}

export default Profile;
