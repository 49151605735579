import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Avatar,
  List,
  ListItem,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ImageList,
  ImageListItem,
  IconButton,
  Tooltip,
  Dialog,
  Snackbar,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShareIcon from '@mui/icons-material/Share';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router-dom';

const BusinessInfo = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate('/Account'); // Programmatically navigate to the About page
  };

  return (
    <div>
      <Container id="test">
        <Box display="flex" justifyContent="center" mt={2}>
          <Typography variant="h4" gutterBottom>
            Website Features
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ boxShadow: 3 }}>
              <br />
              <Box display="flex" justifyContent="center" mt={2} sx={{color: '#2575fc'}}>
                <AccountCircleIcon fontSize="large" />
              </Box>
              <CardContent>
                <Typography variant="h5" component="div" align="center" >
                  User Profiles
                </Typography>
                <br />
                <Typography variant="body2" color="text.secondary">
                  Description of User Profiles.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  sx={{
                    color: 'black',
                    '&:hover': {
                      color: '#2575fc',
                    },
                  }}
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ boxShadow: 3 }}>
              <br />
              <Box display="flex" justifyContent="center" mt={2} sx={{color: '#2575fc'}}>
                <ShareIcon fontSize="large" />
              </Box>
              <CardContent>
                <Typography variant="h5" component="div" align="center">
                  Content Sharing
                </Typography>
                <br />
                <Typography variant="body2" color="text.secondary">
                  Description of Content Sharing.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  sx={{
                    color: 'black',
                    '&:hover': {
                      color: '#2575fc',
                    },
                  }}
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ boxShadow: 3 }}>
              <br />
              <Box display="flex" justifyContent="center" mt={2} sx={{color: '#2575fc'}}>
                <ChatIcon fontSize="large" />
              </Box>
              <CardContent>
                <Typography variant="h5" component="div" align="center" >
                  Instant Messaging
                </Typography>
                <br />
                <Typography variant="body2" color="text.secondary">
                  Description of Instant Messaging.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  sx={{
                    color: 'black',
                    '&:hover': {
                      color: '#2575fc',
                    },
                  }}
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BusinessInfo;
