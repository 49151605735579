import React from 'react';
import { Grid, Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const blogs = [
  {
    title: 'Blog Post 1',
    excerpt: 'This is a short summary of the first blog post.',
  },
  {
    title: 'Blog Post 2',
    excerpt: 'This is a short summary of the second blog post.',
  },
  {
    title: 'Blog Post 3',
    excerpt: 'This is a short summary of the third blog post.',
  },
];

const BlogSection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Latest Blog Posts
      </Typography>
      <Grid container spacing={4}>
        {blogs.map((blog, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Item>
              <Typography variant="h6">{blog.title}</Typography>
              <Typography variant="body1">{blog.excerpt}</Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogSection;
