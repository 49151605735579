// Import necessary modules
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css'; // Importing a global CSS file for styling
import App from './App'; // Importing the main App component
import {BrowserRouter} from "react-router-dom";

// Create a root element for React to use
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component inside the root element
root.render(
  <React.StrictMode>
    <BrowserRouter>
     <App />
    </BrowserRouter>
  </React.StrictMode>
);

