import React from 'react';
import { Grid, Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/system';

// Define colors
const lightGray = 'white';
const deepBlueShadow = '0 4px 20px rgba(0, 32, 95, 0.5)'; // Existing shadow color
const strongShadow = '0 6px 30px rgba(0, 32, 95, 0.7)'; // Stronger shadow color
const headerColor = 'black'; // Header text color

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  borderRadius: '10px',
  backgroundColor: lightGray, // Background color for the card
  boxShadow: deepBlueShadow, // Default shadow color
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: strongShadow, // Stronger shadow on hover
  },
}));

const portfolioItems = [
  {
    title: 'Vanilla',
    description: 'A project using pure JavaScript without any frameworks or libraries.',
    imageUrl: 'https://via.placeholder.com/300',
  },
  {
    title: 'React',
    description: 'A mobile application built using React Native for cross-platform development.',
    imageUrl: 'https://via.placeholder.com/300',
  },
  {
    title: 'MERN Stack',
    description: 'A full-stack application using MongoDB, Express, React, and Node.js.',
    imageUrl: 'https://via.placeholder.com/300',
  },
];

const PortfolioSection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ color: headerColor, fontWeight: 'bold' }}>
        Our Portfolio
      </Typography>
      <Grid container spacing={4}>
        {portfolioItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardMedia
                component="img"
                alt={item.title}
                height="140"
                image={item.imageUrl}
                title={item.title}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ color: headerColor, fontWeight: 'bold', color: '#2575fc' }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'black' }}>
                  {item.description}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PortfolioSection;

