import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const white = 'white';


const faqs = [
  {
    question: 'What does your Company do?',
    answer: 'Talon is a website development company started by Kieran and Adam geared towards building custom web solutions, allowing your business to adapt to the times and succeed in the modern age.',
  },
  {
    question: 'What experience do you have?',
    answer: 'We are passionate Developers with over 3 years of experience building responsive websites using all your favorite languages, frameworks, and libraries. From Vanilla applications to full stacks, we’re ready to tackle any challenge that comes our way!',
  },
  {
    question: 'How can I hire you?',
    answer: 'If you\'re interested in hiring us, feel free to contact the Admin pages on this Website or you can send any requests to Karchibald98@outlook.com.',
  },
];

const FaqSection = () => {
  return (
    <Box sx={{ p: 4, padding: '0 10%' }}>
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion 
          key={index} 
          sx={{ 
            bgcolor: {white}, 
            color: 'black',
            boxShadow: '0px 4px 8px rgba(0, 32, 95, 0.3)', // Blue shadow with transparency
            transition: 'box-shadow 0.3s',
            '&:hover': {
              boxShadow: '0px 8px 16px rgba(0, 32, 95, 0.5)', // Darker blue shadow on hover
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FaqSection;
