// ArrowButton.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const Button = styled(Link)`
  position: absolute;
  bottom: 20px;  // Adjust as needed
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  color: black;
  z-index: 10;
  text-decoration: none;

  &:hover {
    color: #2575fc; // Change color on hover if desired
  }


  /* Parallax effect */
  &:before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateZ(0) scale(1.5);
    transition: transform 0.3s;
  }

  &:hover:before {
    transform: translateX(-50%) translateY(-10px) scale(1.5);
  }
`;

const ArrowButton = ({ to }) => {
  return (
    <Button to={to} smooth={true} duration={500}>
      ↓
    </Button>
  );
};

export default ArrowButton;