import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

function UserSearch({ currentUserId }) {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch users from API
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/users');
                setUsers(response.data);
                // Exclude the current user from the initial list
                setFilteredUsers(response.data.filter(user => user._id !== currentUserId));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Error fetching users');
                setLoading(false);
            }
        };

        fetchUsers();
    }, [currentUserId]);

    // Handle search input change
    const handleSearchInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        filterUsers(query);
    };

    // Filter users based on search query
    const filterUsers = (query) => {
        const queryLower = query.toLowerCase();
        const filtered = users.filter(user =>
            user._id !== currentUserId && // Exclude current user from search results
            (user.firstName.toLowerCase().includes(queryLower) || 
             user.lastName.toLowerCase().includes(queryLower))
        );
        setFilteredUsers(filtered);
    };

    const handleMessageClick = (userId) => {
        // Implement the message sending functionality here
        console.log(`Send a message to user with ID: ${userId}`);
    };

    const handleAddFriendClick = (userId) => {
        // Implement the friend request sending functionality here
        console.log(`Send a friend request to user with ID: ${userId}`);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 4 }}>
                <TextField
                    label="Search by First or Last Name"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                />
            </Box>
            <Box component="ul" sx={{ listStyle: 'none', p: 0 }}>
                {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                        <Box key={user._id} component="li" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={user.profilePicture}
                                    alt="Profile"
                                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                />
                                <Link to={`/profile/${user._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography>{user.firstName} {user.lastName}</Typography>
                                </Link>
                            </Box>
                            <Button 
                                variant="contained" 
                                size="small" 
                                onClick={() => handleMessageClick(user._id)}
                            >
                                Message
                            </Button>
                            <Button 
                                variant="contained" 
                                size="small" 
                                onClick={() => handleAddFriendClick(user._id)}
                            >
                                Add
                            </Button>
                        </Box>
                    ))
                ) : (
                    <Typography>No users found</Typography>
                )}
            </Box>
        </Box>
    );
}

export default UserSearch;
