// HeroSection.js
import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import ArrowButton from './ArrowButton'; // Import ArrowButton component
import { useNavigate } from 'react-router-dom';


const HeroContainer = styled.section`
position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: linear-gradient(
    180deg, 
    RGB(37, 117, 252, 0.8) 30%, 
    RGB(241, 236, 236, 1) 90%
  );
  color: black;
  text-align: center;
  padding: 0 20px;
`;

const Heading = styled(animated.h1)`
 font-family: 'Merriweather', serif;
  font-weight: 700;
  font-size: 4rem;
  margin: 0;
`;

const Subheading = styled(animated.p)`
  font-size: 1.5rem;
  margin: 20px 0;
`;

const Button = styled(animated.button)`
  background: #fff;
  color: #black;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #e4e4e4;
    color: black;
  }
`;

const HeroSection = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to handle button click
  const handleLearnMoreClick = () => {
      navigate('/About'); // Programmatically navigate to the About page
  };

  const headingProps = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-50px)' }, delay: 200 });
  const subheadingProps = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-50px)' }, delay: 400 });
  const buttonProps = useSpring({ opacity: 1, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(-50px)' }, delay: 600 });

  return (
    <HeroContainer>
      <Heading style={headingProps}>Welcome to Talon</Heading>
      <Subheading style={subheadingProps}>We Are Passionate Developers With the Goal of Bringing Your Vision to Life.</Subheading>
        <Button className="learn-more-button" onClick={handleLearnMoreClick}>Learn More</Button>
      <ArrowButton to="test" />
    </HeroContainer>
  );
};

export default HeroSection;
