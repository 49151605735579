// validationSchema.js
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';

// Function to check password strength
const validatePasswordStrength = (password) => {
    const result = zxcvbn(password);
    const score = result.score; // 0 to 4

    if (score < 2) { // 2 corresponds to "moderate" strength
        return 'Password must be at least moderate strength.';
    }
    return null;
};

// Define your validation schema
export const validationSchema = Yup.object({
    firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'First Name must contain only letters')
        .required('First Name is required'),
    lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Last Name must contain only letters')
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .test('password-strength', 'Password must be at least moderate strength.', value => {
            const error = validatePasswordStrength(value);
            if (error) throw new Yup.ValidationError(error);
            return true;
        }),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});
