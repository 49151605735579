import { Link } from "react-router-dom"
import './Footer.css'; // Import the new stylesheet
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import logo from './images/logo.png'; 

export default function Navbar() {

    return (

    <footer className="unique-footer">
        <div className="unique-footer-container">
        <div className="unique-footer-section">
                <h4>Contact</h4>
                <p>Address: 99 Scott Street, Galashiels, TD1 1DU</p>
                <p>Email: Support@Talon.com</p>
                <p>Phone: 07450297866</p>
        </div>
        <div className="unique-footer-section">
            <Link to="/" className="enhanced-site-title">
                <img src={logo} alt="Talon Inc. Logo" className="enhanced-logo" />
            </Link>
                <p>Follow us on social media to stay updated on our latest projects, announcements, and information.</p>
        </div>
            <div className="unique-footer-section">
                <h4>Follow Us</h4>
                <br/>
                <div className="footer-social">
                    <a href="https://facebook.com" aria-label="Facebook">
                        Facebook <FaFacebook />
                    </a>
                    <a href="https://twitter.com" aria-label="Twitter">
                        Twitter <FaTwitter />
                    </a>
                    <br/>
                    <a href="https://instagram.com" aria-label="Instagram">
                        Instagram <FaInstagram />
                    </a>
                    <a href="https://linkedin.com" aria-label="LinkedIn">
                        LinkedIn <FaLinkedin />
                    </a>
                </div>
            </div>
        </div>
        <div className="unique-footer-bottom">
            <p>&copy; 2024 Talon. All rights reserved.</p>
        </div>
    </footer>

    );

}