import React from 'react';
import StyledTables from './StyledTables';
import FeatureSection from './components/FeatureSection';
import PricingSection from './components/PricingSection';
import FaqSection from './components/FaqSection';

function Services() {
  return (
    <div className="container">
      <FeatureSection/>
      <FaqSection/>
    </div>
  );
}

export default Services;
