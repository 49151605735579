import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ForgotPassword = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/api/request-reset-password', { email });
            setMessage('Password reset email sent!');
        } catch (error) {
            setMessage('Error sending reset email');
        }
    };

  return (
    <div className="PaddedContainer">
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h2 className="text-center">Forgot Your Password?</h2>
            <p className="text-center">Enter your email address to reset your password.</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-4 w-100">
                Submit
              </Button>
            </Form>
            {message && (
              <div className="text-center mt-3 text-success">
                {message}
              </div>
            )}
            <div className="text-center mt-3">
              <Link to="/Account">Back to Login</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
