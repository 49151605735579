import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css'; // Import the new stylesheet
import logo from './images/logo.png'; 

export default function Navbar() {
        const [menuOpen, setMenuOpen] = useState(false);
        const [message, setMessage] = useState('');

        useEffect(() => {
          // Fetch data from the backend API
         fetch('/api')
           .then(response => response.json())
           .then(data => setMessage(data.message))
            .catch(error => console.error('Error fetching data:', error));
         }, []);
    
        const isAuthenticated = !!localStorage.getItem('token');
    
        const handleLogout = () => {
            localStorage.removeItem('token');
            window.location.href = '/';
        };



    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="enhanced-navbar">
            <Link to="/" className="enhanced-site-title">
                <img src={logo} alt="Talon Inc. Logo" className="enhanced-logo" />
            </Link>
            <div className="enhanced-navbar-hamburger" onClick={toggleMenu}>
                &#9776;
            </div>
            <ul className={`enhanced-navbar-links ${menuOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                </li>
                <li>
                    <Link to="/About" onClick={toggleMenu}>About</Link>
                </li>
                <li>
                    <Link to="/Services" onClick={toggleMenu}>Services</Link>
                </li>
                
                
                {isAuthenticated && (
                    <li>
                    <Link to="/Contact" onClick={toggleMenu}>Chat</Link>
                </li>
                )}
                {!isAuthenticated && (
                    <li>
                        <Link to="/Account" onClick={toggleMenu}>Account</Link>
                    </li>
                )}
                {isAuthenticated && (
                    <li>
                        <Link to="/Profile" onClick={toggleMenu}>Account</Link>
                    </li>
                )}
                {isAuthenticated && (
                    <li>
                        <Link to="/Logout" onClick={toggleMenu}>Logout</Link>
                    </li>
                )}
                
            </ul>
        </nav>
    );
}
