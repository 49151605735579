// Contact.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Contact() {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    message: '',
  });
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('Not authenticated');
            return;
        }

        try {
            const response = await axios.get('http://localhost:5000/api/profile', {
                headers: {
                    'Authorization': token
                }
            });
            setUser(response.data);

            // Set the form data's name field to the user's name
            setFormData(prevFormData => ({
              ...prevFormData,
              name: response.data.name
            }));
        }  catch (error) {
        console.error('Error fetching user profile:', error);
        setError('Error fetching user profile');
      }
    };

    

    fetchUser();
    fetchComments();
  }, []);


  const fetchComments = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/comments');
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user.firstName || !user.lastName) {
      alert('User not authenticated');
      return;
     }

    if (formData.message.length < 10) {
      setError('Message must be at least 10 characters long.');
      return;
    }

    const currentTimestamp = new Date().toISOString();

    try {
      const dataToSubmit = {
        name: `${user.firstName} ${user.lastName}`,
        message: formData.message,
        timestamp: currentTimestamp,
      };

      await axios.post('http://localhost:5000/api/comments', dataToSubmit);
      setFormData({ message: '' });
      fetchComments(); // Refresh comments after submission
      setError(null);
    } catch (error) {
      console.error('Error submitting the comment:', error);
      alert('Error submitting the comment');
    }
  };

  // Handle Enter key to submit the form
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // Format timestamp for display
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="PaddedContainer">
      <div className="container mt-5">
        <h2 className="mb-4">Just Chatting</h2>
        <p className="mb-4">
          Join our global chatroom and connect with other like-minded individuals looking to develop their skills and expand their knowledge. Break down barriers, get to know others, and make some memories. 
        </p>

    

        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className="form-control"
              rows="4"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
        
        <br />
          {/* Display error message */}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <div className="container mt-5">
          <h2 className="mb-4">Live Chat</h2>
          {comments.length === 0 ? (
            <p>No comments yet.</p>
          ) : (
            <ul className="list-unstyled">
              {comments.map((comment) => (
                <li key={comment._id} className="mb-3 comment-item">
                  <div className="card p-3">
                    <p className="mb-0">
                      <strong>{formatTimestamp(comment.timestamp)} : {comment.name}:</strong> {comment.message}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contact;