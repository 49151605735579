import React from 'react';
import { Box, Typography, Avatar, Grid, Paper, Rating } from '@mui/material';

const testimonials = [
  {
    name: 'Audrey',
    feedback: 'This is the best service I have ever used!',
    avatar: 'https://via.placeholder.com/150',
    rating: 5,
  },
  {
    name: 'Lesley',
    feedback: 'Absolutely wonderful! Highly recommended.',
    avatar: 'https://via.placeholder.com/150',
    rating: 4,
  },
  {
    name: 'Craig',
    feedback: 'Fantastic experience! I will definitely come back.',
    avatar: 'https://via.placeholder.com/150',
    rating: 4,
  },
];

const TestimonialSection = () => {
  return (
    <Box sx={{ p: { xs: 2, md: '0 25%' } }}>
      <br /> <br />
      <Typography variant="h4" gutterBottom align="center">
        What Our Clients Say
      </Typography>
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid 
            item 
            xs={12} 
            key={index} 
            sx={{ 
              display: 'flex', 
              justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
              position: 'relative' 
            }}
          >
            <Box
              sx={{
                maxWidth: 400,
                mx: 2,
                position: 'relative',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: '10px',
                  width: '0',
                  height: '0',
                  borderStyle: 'solid',
                  borderWidth: '10px',
                  borderColor: index % 2 === 0
                    ? 'transparent white transparent transparent'
                    : 'transparent transparent transparent white',
                  left: index % 2 === 0 ? '-20px' : 'auto',
                  right: index % 2 === 0 ? 'auto' : '-20px'
                }
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  backgroundColor: 'white',
                  boxShadow: 'none',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                  <Avatar src={testimonial.avatar} sx={{ width: 56, height: 56, mr: 2 }} />
                  <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 'bold', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'normal', 
                        wordWrap: 'break-word'
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                    <Rating name="read-only" value={testimonial.rating} readOnly sx={{ mt: 1 }} />
                  </Box>
                </Box>
                <Typography 
                  variant="body1" 
                  color="textSecondary" 
                  sx={{ 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'normal', 
                    wordWrap: 'break-word' 
                  }}
                >
                  {testimonial.feedback}
                </Typography>
              </Paper>
            </Box>
          </Grid>
        ))}
      </Grid>
      <br/>
    </Box>
  );
};

export default TestimonialSection;
