import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import heroImage from '../images/Hero/light.jpg'; // Import the image

// Global style for custom fonts
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Montserrat:wght@900&display=swap');
  body {
    font-family: 'Nunito', sans-serif;
  }
`;

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

// Styled components
const HeroBox = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  height: 30vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const MainText = styled(Typography)`
  
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  animation: ${fadeIn} 2s ease-in-out, ${slideInFromLeft} 1s ease-in-out;
  margin-bottom: 1rem;
`;

const SubtitleText = styled(Typography)`
  font-family: 'Nunito', sans-serif;
  animation: ${fadeIn} 2s ease-in-out, ${slideInFromRight} 1s ease-in-out;
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  font-size: 1.2rem;
  padding: 0.75rem 2rem;
  animation: ${fadeIn} 3s ease-in-out;
`;

const HeroSection = () => {
  return (
    <HeroBox>
      <GlobalStyle />
      <MainText variant="h2">         </MainText>
      <br/>
      <SubtitleText variant="h5">Expert website development using cutting-edge technologies.</SubtitleText>
      <br />
      <Link to="/About" style={{ textDecoration: 'none' }}>
        <StyledButton variant="contained" color="secondary">
          Learn More
        </StyledButton>
      </Link>
    </HeroBox>
  );
};

export default HeroSection;
