import React from 'react';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import TeamSection from './components/TeamSection';
import PortfolioSection from './components/PortfolioSection';
import CompanyBio from './components/CompanyBio';

const About = () => {
  return (
    <div className="container">
      <CompanyBio />
      <TeamSection />
      <PortfolioSection />
    </div>
  );
};

export default About;
