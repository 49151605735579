import React from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Button } from '@mui/material';
import companyImage from '../images/2.png'; // Replace with your image path

// Define colors
const lightGray = 'white';
const deepBlue = 'black'; // Shadow color
const accentBlue = 'black'; 

const CompanyBio = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Card sx={{
            borderRadius: '8px',
            boxShadow: `0 4px 10px ${deepBlue}`, // Existing shadow color
          }}>
            <CardMedia
              component="img"
              alt="Company Image"
              height="200"
              image={companyImage}
              title="Company Image"
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{
            bgcolor: lightGray,
            color: '#000', // Dark text color for contrast
            borderRadius: '8px',
            boxShadow: `0 4px 10px ${deepBlue}`, // Existing shadow color
          }}>
            <CardContent>
              <Typography variant="h4" component="h2" gutterBottom sx={{ color: 'black' }}>
                About Us
              </Typography>
              <Typography variant="body1" paragraph>
                At Talon, we believe our clients shouldn't have to choose between functionality and usability; they deserve the best of both worlds. Our vision is to bring your ideas to life, allowing you to focus on growing your business and flourishing in the 21st century. Whether that's a simple webpage to establish your online presence or a scalable application with global aspirations, no job is too big or too small for the team here at Talon.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanyBio;
