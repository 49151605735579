import React from 'react';
import { Grid, Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundImage: 'url(https://via.placeholder.com/150)',
  backgroundSize: 'cover',
  height: 150,
}));

const GallerySection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gallery
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Item>Image 1</Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Item>Image 2</Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Item>Image 3</Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GallerySection;
