import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal, Typography, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import axios from 'axios';

const MessagingModal = () => {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = localStorage.getItem('token');
    const isAuthenticated = !!token;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (open && isAuthenticated) {
            const fetchUsers = async () => {
                try {
                    const response = await axios.get('http://localhost:5000/api/users', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Pass the token in the headers
                        },
                    });
                    setUsers(response.data);
                } catch (err) {
                    setError('Error fetching users.');
                } finally {
                    setLoading(false);
                }
            };

            fetchUsers();
        }
    }, [open, isAuthenticated, token]);

    if (!isAuthenticated) {
        return null; // Do not render the component if the user is not authenticated
    }

    return (
        <div>
            <IconButton
                onClick={handleOpen}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    zIndex: 1000,
                    backgroundColor: '#3f51b5',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#303f9f',
                    },
                }}
            >
                <PeopleIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: '90vw',  // 90% of viewport width on extra-small screens
                            sm: 400,     // Fixed width on small screens and up
                        },
                        height: {
                            xs: '80vh',  // 80% of viewport height on extra-small screens
                            sm: 500,     // Fixed height on small screens and up
                        },
                        backgroundColor: 'white',
                        borderRadius: 1,
                        boxShadow: 24,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <Typography variant="h6" component="div">
                        Users List
                    </Typography>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                            <CircularProgress />
                        </Box>
                    ) : error ? (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    ) : (
                        <Box
                            sx={{
                                flex: 1,
                                overflowY: 'auto',
                                marginBottom: 1,
                                border: '1px solid #ddd',
                                borderRadius: 1,
                            }}
                        >
                            <List>
                                {users.map((user) => (
                                    <ListItem key={user._id}>
                                        <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default MessagingModal;
