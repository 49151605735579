import React from 'react';
import { Grid, Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ServicesSection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Our Services
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Item>Service 1</Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Item>Service 2</Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Item>Service 3</Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesSection;
