// PasswordStrengthMeter.js
import React, { useState, useEffect } from 'react';
import zxcvbn from 'zxcvbn';
import { ProgressBar } from 'react-bootstrap';

const PasswordStrengthMeter = ({ password }) => {
    const [strength, setStrength] = useState(0);
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        if (password.length === 0) {
            setStrength(0);
            setFeedback('');
            return;
        }

        const result = zxcvbn(password);
        const score = result.score; // 0 to 4
        const feedback = result.feedback.suggestions.join(' ');

        setStrength(score);
        setFeedback(feedback);
    }, [password]);

    const getProgressVariant = (strength) => {
        switch (strength) {
            case 0: return 'danger';
            case 1: return 'warning';
            case 2: return 'info';
            case 3: return 'primary';
            case 4: return 'success';
            default: return 'secondary';
        }
    };

    return (
        <div>
            <ProgressBar
                now={(strength + 1) * 20} // Strength is 0-4, so multiply by 20 to get 0-100
                variant={getProgressVariant(strength)}
                className="mb-2"
            />
            <div className="text-muted">
                {feedback || 'Enter a password to see strength.'}
            </div>
        </div>
    );
};

export default PasswordStrengthMeter;
