import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Searching from './UserSearch';

function Profile() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('Not authenticated');
                return;
            }

            try {
                const response = await axios.get('http://localhost:5000/api/profile', {
                    headers: { 'Authorization': token }
                });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setError('Error fetching profile');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/users');
                setAllUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Error fetching users');
            }
        };

        fetchAllUsers();
    }, []);

    const handleSearchResults = (results) => {
        setSearchResults(results);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const buttonStyle = {
        backgroundColor: '#2575fc',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1a5bbf',
        },
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <div>No user data</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const filteredUsers = allUsers.filter(u => u._id !== user._id);
    const uniqueUsers = Array.from(new Map(filteredUsers.map(user => [user._id, user])).values());

    const searchUserProfiles = searchResults.length > 0 
        ? searchResults 
        : uniqueUsers;

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ mt: 5, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
                <Box sx={{ flex: 1 }}>
                    <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 1, marginLeft: 2 }}>
                        <Typography variant="h4" component="h1" align="center" mb={4}>Profile</Typography>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 2 }}>
                            <Box sx={{ textAlign: 'center', mb: { xs: 2, md: 0 } }}>
                                <img
                                    src={user.profilePicture}
                                    alt="Profile"
                                    style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '20px', borderRadius: '50%' }}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 1 }}>
                                    <Typography><strong>Name:</strong> {user.firstName} {user.lastName}</Typography>
                                    <Typography><strong>Email:</strong> {user.email}</Typography>
                                    <Typography><strong>Bio:</strong> {user.bio}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', mt: 4 }}>
                            <Link to="/EditProfile">
                                <Button variant="contained" sx={buttonStyle}>Edit Profile</Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 1 }}>
                        <Searching currentUserId={user._id} />
                        <Typography variant="h4" component="h1" align="center" mb={4}>Search</Typography>
                        <SearchBar onSearch={handleSearchResults} />
                        <Box component="ul" sx={{ listStyle: 'none', p: 0 }}>
                            {searchUserProfiles.length > 0 ? (
                                searchUserProfiles.map((profileUser) => (
                                    <Box key={profileUser._id} component="li" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={profileUser.profilePicture}
                                                alt="Profile"
                                                style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                                            />
                                            <Link to={`/profile/${profileUser._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                <Typography>{profileUser.firstName} {profileUser.lastName}</Typography>
                                            </Link>
                                        </Box>
                                        <Link to={`/chat/${profileUser._id}`}>
                                            <Button variant="contained" size="small">Message</Button>
                                        </Link>
                                        <Button variant="contained" size="small">Add</Button>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No users found</Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                    Message functionality is not implemented yet.
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Profile;
