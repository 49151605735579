import React from 'react';
import { Grid, Box, Typography, Avatar, Paper } from '@mui/material';
import { styled } from '@mui/system';

// Define colors
const lightGray = 'white';
const deepBlue = 'black'; // Shadow color
const accentBlue = 'black'; 


const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: lightGray, // Maintain existing color
  borderRadius: '8px',
  boxShadow: `0 4px 10px ${accentBlue}`, // Existing shadow color
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 6px 24px ${accentBlue}`, // Enhanced shadow on hover
    color: '#2575fc'
  },
}));

const teamMembers = [
  {
    name: 'Kieran Archibald',
    position: 'Developer',
    avatar: 'https://via.placeholder.com/150',
  },
  {
    name: 'Adam Lowrie',
    position: 'CEO',
    avatar: 'https://via.placeholder.com/150',
  },
];

const TeamSection = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Item>
              <Avatar 
                src={member.avatar} 
                sx={{ 
                  width: 112, 
                  height: 112, 
                  margin: '0 auto 10px',
                  border: `1px solid ${accentBlue}`, // Accent border for avatar
                }} 
              />
              <Typography variant="h6" sx={{ color: accentBlue }}>
                {member.name}
              </Typography>
              <Typography variant="body1">
                {member.position}
              </Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TeamSection;

