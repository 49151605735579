import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Home.css'; // Import the new stylesheet
import img1 from './images/stockCover.jpg';
import img4 from './images/stock4.jpg';
import img5 from './images/stock5.jpg';
import img7 from './images/stock7.jpg';
import Hero from './components/Hero2';
import Testimonials from './components/TestimonialSection';
import Info from './components/BusinessInfo';


function Home() {
  return (

    <div className="unique-home-container">
        <Hero />
        <Info />
        <Testimonials />
     <div className="container">


      <section className="unique-gallery">
        <h2 className="unique-section-title">Gallery</h2>
        <div className="unique-gallery-grid">
          <img src={img4} alt="Gallery item 1" className="unique-gallery-item" />
          <img src={img5} alt="Gallery item 2" className="unique-gallery-item" />
          <img src={img7} alt="Gallery item 3" className="unique-gallery-item" />
        </div>
      </section>

      
      
    </div>
    </div>
  );
}

export default Home;
